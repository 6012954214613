// /* You can add global styles to this file, and also import other style files */

// @import "assets/scss/argon-dashboard.scss";

// /* Importing Bootstrap SCSS file. */
// // @import 'bootstrap/scss/bootstrap';

// .card-profile {
//     transition: all .5s;
// }

// .card-profile:hover {
//     transform: scale(1.04);
//     transition: all .5s;
// }

// .capitalized {
//     text-transform: capitalize;
// }

@import "./assets/css/nucleo-svg.css";
@import "./assets/css/nucleo-icons.css";


.icon-shape {
    width: 48px !important;
    height: 48px !important;
    background-position: center !important;
    border-radius: 0.75rem !important;
}

.ngx-color-form {
    display: inline-block; 
    margin:5px; 
}

.form-control-for-form {
    border: 1px solid #fff !important; 
    padding: 0px !important;
}

.normalize-img-size {
    min-height: 200px; 
    max-height: 200px; 
    width: auto;
}